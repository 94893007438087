import * as React from 'react'
import { FormattedMessage, Link } from 'gatsby-plugin-react-intl'

import { Layout } from '../components/layout'
import Seo from '../components/seo'
import { StaticImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import { fontSize, spacing, transition } from '../style/theme'

const NotFountContainer = styled.div`
  padding-top: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100vh;
  h1 {
    margin: ${spacing.super} 0 ${spacing.base};
    color: #434343;
    font-size: ${fontSize.plus};
    font-weight: 500;
    line-height: 1.1;
  }
`
const ButtonGroupContainer = styled.div`
  a,
  button {
    margin: ${spacing.base} ${spacing.small};
    display: inline-block;
    width: 150px;
    height: ${fontSize.plus};
    line-height: ${fontSize.plus};
    background-color: #00b0c4;
    border-radius: 20px;
    color: white;
    font-size: ${fontSize.base};
    border: none;
    transition: ${transition('background-color')};
    cursor: pointer;
    :hover {
      background-color: #0691a0;
    }
  }
`

const NotFoundPage = () => {
  const handleReturn = () => {
    window.history.go(-1)
  }
  return (
    <Layout>
      <Seo title='404: Not found' />
      <NotFountContainer>
        <StaticImage className='not-found-image' alt='' src='../images/not-found/not-found.png' />
        <h1>
          <FormattedMessage id='not_found_title' />
        </h1>
        <ButtonGroupContainer>
          <Link to='/'>
            <FormattedMessage id='menu_home' />
          </Link>
          <button onClick={handleReturn} aria-label='return'>
            <FormattedMessage id='not_found_return_pre' />
          </button>
        </ButtonGroupContainer>
      </NotFountContainer>
    </Layout>
  )
}

export default NotFoundPage
